import React, { useState, useEffect } from 'react';
import reactDom from 'react-dom';
import './Home.css';
import logo from './imagens/logo.png';

function Home() {

    document.title = "Convert Text";

    const [mod, setMod] = useState("normal");
    var textIn = "";

    function pressTextIn() {
        document.getElementById('btnCopiar').innerHTML = "Copiar";

        textIn = document.getElementById('textIn').value;

        var caracteresCount = document.getElementById('caracteresCount');
        caracteresCount.innerHTML = textIn.length;

        switch (mod) {
            case "mai":
                reactDom.render(textIn.toUpperCase(), document.getElementById('textOut'));
                break;

            case "min":
                reactDom.render(textIn.toLowerCase(), document.getElementById('textOut'));
                break;

            case "priMai":
                if (textIn != "") {
                    let priMai = textIn.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
                    reactDom.render(priMai, document.getElementById('textOut'));
                }
                break;

            case "remEsp":
                reactDom.render(textIn.replace(/\s/g, ''), document.getElementById('textOut'));
                break;

            default:
                reactDom.render(textIn, document.getElementById('textOut'));
                break;
        }
    }

    useEffect(() => {

        var textIn = document.getElementById('textIn').value;

        let btnMai = document.getElementById('btnMai');
        let btnMin = document.getElementById('btnMin');
        let btnPriMai = document.getElementById('btnPriMai');
        let btnNormal = document.getElementById('btnNormal');
        let btnRemEsp = document.getElementById('btnRemEsp');

        switch (mod) {
            case "mai":
                reactDom.render(textIn.toUpperCase(), document.getElementById('textOut'));

                btnMai.classList.add('active');
                btnMin.classList.remove('active');
                btnPriMai.classList.remove('active');
                btnNormal.classList.remove('active');
                btnRemEsp.classList.remove('active');
                break;

            case "min":
                reactDom.render(textIn.toLowerCase(), document.getElementById('textOut'));

                btnMai.classList.remove('active');
                btnMin.classList.add('active');
                btnPriMai.classList.remove('active');
                btnNormal.classList.remove('active');
                btnRemEsp.classList.remove('active');
                break;

            case "priMai":
                if (textIn != "") {
                    let priMai = textIn.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
                    reactDom.render(priMai, document.getElementById('textOut'));
                }

                btnMai.classList.remove('active');
                btnMin.classList.remove('active');
                btnPriMai.classList.add('active');
                btnNormal.classList.remove('active');
                btnRemEsp.classList.remove('active');
                break;

            case "remEsp":
                reactDom.render(textIn.replace(/\s/g, ''), document.getElementById('textOut'));

                btnMai.classList.remove('active');
                btnMin.classList.remove('active');
                btnPriMai.classList.remove('active');
                btnNormal.classList.remove('active');
                btnRemEsp.classList.add('active');
                break;

            case "normal":
                reactDom.render(textIn, document.getElementById('textOut'));

                btnMai.classList.remove('active');
                btnMin.classList.remove('active');
                btnPriMai.classList.remove('active');
                btnNormal.classList.add('active');
                btnRemEsp.classList.remove('active');
                break;

            default:
                reactDom.render(textIn, document.getElementById('textOut'));

                btnMai.classList.remove('active');
                btnMin.classList.remove('active');
                btnPriMai.classList.remove('active');
                btnNormal.classList.add('active');
                break;
        }
    }, [mod]);

    function copiar() {
        let textArea = document.getElementById('textOut');
        textArea.select();
        document.execCommand('copy');

        document.getElementById('btnCopiar').innerHTML = "Copiado!";
    }

    return (
        <div>

            <header>
                <a target="_blank" href="https://viniciushnf.com/" >
                    <img src={logo} />
                </a>
            </header>

            <div id="main">
                <div id="containerText">

                    <div id="boxTextL" className="boxText">
                        <h1>Insira o texto</h1>
                        <textarea onKeyUp={() => pressTextIn()} id="textIn"></textarea>
                        <div className="bottom">
                            <p>Quantidade de caracteres: <span id="caracteresCount">0</span></p>
                        </div>
                    </div>

                    <div id="boxTextR" className="boxText">
                        <h1>Texto modificado</h1>
                        <textarea id="textOut" readOnly></textarea>
                        <div className="bottom">
                            <button onClick={() => copiar()} id="btnCopiar">Copiar</button>
                        </div>
                    </div>

                </div>
            </div>

            <div id="containerButtons">
                <div className="center">
                    <button id="btnNormal" className="btn" onClick={() => setMod("normal")}>Normal</button>
                    <button id="btnMai" className="btn" onClick={() => setMod("mai")}>MAIÚSCULO</button>
                    <button id="btnMin" className="btn" onClick={() => setMod("min")}>minúsculo</button>
                    <button id="btnPriMai" className="btn" onClick={() => setMod("priMai")}>Primeira Letra De Cada Palavra Maiúsculo</button>
                    <button id="btnRemEsp" className="btn" onClick={() => setMod("remEsp")}>RemoverEspaços</button>
                </div>
            </div>

        </div>
    );
}


export default Home;